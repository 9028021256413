<template>
    <div class="table-responsive">
        <table class="custom-table">
            <thead>
                <tr>
                    <th rowspan="2" v-if="invoice_data.length > 0">
                        <CheckboxButton v-if="status !== 'reconciled'" :options="select_all_options" :name="`${invoice_type}-dh-invoice-user-selection-all`" :value="allIncluded" @checkbox-change="handleIncludeAll"/>
                    </th>
                    <th rowspan="2">Booking Date & Time</th>
                    <th rowspan="2">Booking ID</th>
                    <th rowspan="2">Service</th>
                    <th rowspan="2">Booking Cost</th>
                    <th rowspan="2">Client</th>
                    <th colspan="2">Cost Center</th>
                    <th rowspan="2" style="width: 200px;">Comment</th>
                    <th rowspan="2" v-if="invoice_data.length > 0">Action</th>
                </tr>
                <tr>
                    <th>Code</th>
                    <th>Value</th>
                </tr>
            </thead>

            <tbody>
                <template v-if="invoice_data.length > 0">
                    <tr v-for="(invoice, i) in invoice_data"
                        :class="[{ 'error_row': (error_status && error_invoices.includes(invoice.id)) }]">
                        <td>
                            <template v-if="status === 'reconciled'">
                                <template v-if="invoice.is_included === 1">
                                    <Badge type="success">Included</Badge>
                                </template>
                                <template v-if="invoice.is_included === 0">
                                    <Badge type="danger">Dropped</Badge>
                                </template>
                            </template>
                            <template v-else>
                                <RadioButtonGroup :name="`${invoice_type}-invoice-user-selection-${invoice.id}`"
                                    padding="5px 15px" gap="5px" :options="invoice_options" :value="invoice.is_included"
                                    @input-change="(data) => handleUserSelection(data, invoice)" />
                            </template>
                        </td>
                        <td>
                            <template v-if="status === 'reconciled'">
                                {{ invoice.booking.created_at | formatDateTime }}
                            </template>
                            <template v-else>
                                {{ invoice.created_at | formatDateTime }}
                            </template>
                        </td>
                        <td>
                            <template v-if="status === 'reconciled'">
                                {{`${invoice.booking.booking_prefix}${invoice.booking.booking_id}` }}
                            </template>
                            <template v-else>
                                {{`${invoice.booking_prefix}${invoice.booking_id}` }}
                            </template>
                        </td>
                        <td>DH Messenger</td>
                        <td>
                            <template v-if="status === 'reconciled'">
                                $ {{ invoice.booking.cost.toFixed(2) }}
                            </template>
                            <template v-else>
                                $ {{ invoice.cost.toFixed(2) }}
                            </template>
                        </td>
                        <td>
                            <template v-if="status === 'reconciled'">
                                {{ invoice.booking.client.toUpperCase() }}
                            </template>
                            <template v-else>
                                {{ invoice.client.toUpperCase() }}
                            </template>
                        </td>
                        <td>
                            <template v-if="status === 'reconciled'">
                                {{ invoice.booking.custom_code ? invoice.booking.custom_code : ' - ' }}
                            </template>
                            <template v-else>
                                {{ invoice.custom_code ? invoice.custom_code : ' - ' }}
                            </template>
                        </td>
                        <td>
                            <template v-if="status === 'reconciled'">
                                {{ invoice.booking.custom_code_value ? invoice.booking.custom_code_value : ' - ' }}
                            </template>
                            <template v-else>
                                {{ invoice.custom_code_value ? invoice.custom_code_value : ' - ' }}
                            </template>
                        </td>
                        <td>{{ invoice.reconciliation_comment || '' }}</td>
                        <td>
                            <div class="action-btns">
                                <template
                                    v-if="!invoice.is_reconciled && (invoice.is_included && invoice.is_included != 0)">
                                    <ActionButton :title="`${(!invoice.reconciliation_comment) ? 'Add' : 'Edit'} Comment`"
                                        type="info" @handle-click="manageReconciliationComment(invoice)" :className="['action-btn-padding']">
                                        <i class="material-icons p-0 m-0">{{ `${(!invoice.reconciliation_comment) ? 'chat' :
                                            'edit'}` }}</i>
                                    </ActionButton>
                                </template>
                            </div>
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td :colspan="invoice_type == 'unmatched' ? '10' : '14'">No Invoice Data Found</td>
                    </tr>
                </template>
            </tbody>
        </table>

    </div>
</template>
<script>
import moment from "moment";
import ReconStatus from "./ReconStatus.vue";
import ActionButton from "../components/ActionButton.vue";
import Badge from "../components/Badge.vue";
import RadioButtonGroup from "../components/RadioButtonGroup.vue";
import CheckboxButton from "../components/CheckboxButton.vue";

export default {
    name: "MessengerInvoiceDataTable",
    props: {
        invoice_data: {
            required: true
        },
        invoice_type: {
            required: true
        },
        checked_invoices: {
            type: Object,
            required: true
        },
        error_status: {
            type: Boolean,
            default: false,
        },
        status: {
            type:String,
        },
        error_invoices: {
            type: Array,
            default: [],
        }
    },
    components: {
        ReconStatus,
        ActionButton,
        RadioButtonGroup,
        Badge,
        CheckboxButton
    },
    filters: {
        formatDate(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('DD/MM/YYYY')
        },
        formatDateTime(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('YYYY/MM/DD HH:mm');
        }
    },
    data() {
        return {
            invoice_options: [
                { label: 'Include', value: '1', type: 'success' },
                { label: 'Drop', value: '0', type: 'danger' },
            ],
            select_all_options: [
                { label: 'Include All', value: true, type: 'success' },
                { label: 'Drop All', value: false, type: 'danger' },
            ],
            selected: [],
            groupedDataColorCodes: {},
            allIncluded: false,
            includedInvoices:[]
        };
    },
    created() {

        this.loadSelected();

    },
    mounted() {
        this.$parent.$on('clear-selected', () => {
            this.selected = [];
            this.loadSelected();
        });
    },
    watch: {
        invoice_data: {
            handler(newValue) {
                newValue.forEach((item) => {
                    if (item.is_included == 1 && !this.includedInvoices.includes(item.id)) {
                        this.includedInvoices.push(item.id);
                    } else if (item.is_included == 0 && this.includedInvoices.includes(item.id)) {
                        this.includedInvoices = this.includedInvoices.filter(invoiceId => invoiceId !== item.id);
                    }
                });
            },
            deep: true,
        },
        includedInvoices(newValue) {
            this.allIncluded = newValue.length === this.invoice_data.length;
        }
    },
    methods: {
        loadSelected() {
            this.selected = [...this.invoice_data.filter((invoice) => {
                if (!invoice.is_reconciled && !invoice.is_included) {
                    return invoice
                }
            }).map((invoice) => invoice.id)];

            this.emitSelected();
        },
        capitalizeFirstLetter(string) {
            if (!string) return string;

            return string
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        },
        handleSelect() {
            this.emitSelected();
        },
        emitSelected() {
            this.$emit('update-selected', {
                type: this.invoice_type,
                selected: this.selected
            });
        },
        manageReconciliationComment(invoice) {
            this.$emit('manage-comment-center', {
                ...invoice,
                invoice_type: this.invoice_type,
                shipment_number:invoice.connote_no
            });
        },
        handleUserSelection(data, invoice) {
            this.$emit('manage-user-selection', {
                user_selection_data: data,
                invoice,
                invoice_type: this.invoice_type
            });
        },
        handleIncludeAll(data) {

            this.allIncluded = data;

            this.$emit('manage-include-all-selection', {
                selection:  this.allIncluded ? 1 : 0,
                invoice_type: this.invoice_type
            });
        }
    }
};
</script>

<style lang="scss" scoped>
// custome-table
.table-responsive {
    overflow-x: auto;
    margin: 20px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table .error_row {
    background-color: #f8d7da !important;
}

.custom-table .reconciled td {
    background-color: #d1e7dd !important;
    border-color: #A7B99B !important;
    /* Apply border color to td elements */
}

.custom-table th,
.custom-table td {
    padding-inline: 5px;
    padding-block: 12px;
    border: 1px solid #dee2e6;
    text-align: center;
    white-space: nowrap;
    font-size: 15px;
}

.custom-table th {
    background-color: #f8f9fa;
    color: #495057;
}

.custom-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.custom-table tr:hover {
    background-color: #e9ecef;
}

.row-checkbox {
    cursor: pointer;
}

.custom-table .action-btns>.custom-action-button {
    margin-right: 5px;
}

.custom-table .action-btns>.custom-action-button:last-child {
    margin-right: 0 !important;
}

.description-cell {
    text-align: center;
}

.description-cell .link {
    color: #0c55c2 !important;
    font-size:15px;
    font-weight:700;
}

.description-cell .link:hover {
    text-decoration: underline !important;
}

</style>